import { render } from "./AboutPaidServices.vue?vue&type=template&id=56bb9d82"
import script from "./AboutPaidServices.ts?vue&type=script&lang=ts"
export * from "./AboutPaidServices.ts?vue&type=script&lang=ts"

import "./aboutPaidServices.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "56bb9d82"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('56bb9d82', script)) {
    api.reload('56bb9d82', script)
  }
  
  module.hot.accept("./AboutPaidServices.vue?vue&type=template&id=56bb9d82", () => {
    api.rerender('56bb9d82', render)
  })

}

script.__file = "src/components/AboutPaidServices/AboutPaidServices.vue"

export default script