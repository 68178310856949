import { render } from "./TabBar.vue?vue&type=template&id=14e2f453"
import script from "./TabBar.ts?vue&type=script&lang=ts"
export * from "./TabBar.ts?vue&type=script&lang=ts"

import "./tab-bar.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "14e2f453"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('14e2f453', script)) {
    api.reload('14e2f453', script)
  }
  
  module.hot.accept("./TabBar.vue?vue&type=template&id=14e2f453", () => {
    api.rerender('14e2f453', render)
  })

}

script.__file = "src/layouts/MainLayout/components/TabBar/TabBar.vue"

export default script