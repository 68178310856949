import { render } from "./UnauthorizedUser.vue?vue&type=template&id=21fc6b33"
import script from "./UnauthorizedUser.ts?vue&type=script&lang=ts"
export * from "./UnauthorizedUser.ts?vue&type=script&lang=ts"

import "./unauthorizedUser.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "21fc6b33"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('21fc6b33', script)) {
    api.reload('21fc6b33', script)
  }
  
  module.hot.accept("./UnauthorizedUser.vue?vue&type=template&id=21fc6b33", () => {
    api.rerender('21fc6b33', render)
  })

}

script.__file = "src/layouts/MainLayout/components/UnauthorizedUser/UnauthorizedUser.vue"

export default script