import { render } from "./MainLayout.vue?vue&type=template&id=c4f7ce00"
import script from "./MainLayout.ts?vue&type=script&lang=ts"
export * from "./MainLayout.ts?vue&type=script&lang=ts"

import "./main-layout.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c4f7ce00"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c4f7ce00', script)) {
    api.reload('c4f7ce00', script)
  }
  
  module.hot.accept("./MainLayout.vue?vue&type=template&id=c4f7ce00", () => {
    api.rerender('c4f7ce00', render)
  })

}

script.__file = "src/layouts/MainLayout/MainLayout.vue"

export default script